<template>
    <a-modal
      :visible="visible"
      :closable="false"
      :centered="true"
      :title="null"
      :footer="null"
      width="320px"
  >
      <div style="font-size: 16px; font-weight: bold;">
        <a-icon type="exclamation-circle" theme="filled" style="color: #FAAD14;font-size: 18px;"/>
        未找到该手机用户
      </div>
      <div style="display: flex; justify-content: center;margin: 30px 0;">
        请先问用户是否绑定了其它的手机号
      </div>
      <div>
        <a-button style="width: 180px;" type="primary" @click="confirmStatus">确认</a-button>
        <a-button style="margin-left: 15px;" @click="confirmStatus">取消</a-button>
      </div>
    </a-modal>
</template>

<script>

export default {
  name: 'NotPhone',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmStatus() {
      this.$emit('closeNotPhone');
    }
  }
}
</script>
<style lang="less" scoped>
</style>
