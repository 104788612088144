import request from '@/utils/request'

const baseUrl = `${process.env.VUE_APP_API_API}/admin`;
// 入驻审核
export function querySettle(data) {
  return request({
    method: 'post',
    url: `${baseUrl}/business/queryBusinessPage`,
    data
  })
}

// 详情
export function querySettleById(businessById) {
  return request({
    url: `${baseUrl}/business/${businessById}`,
    method: 'get'
  })
}

// 入驻确认
export function confrimSettleIn(data) {
  return request({
    url: `${baseUrl}/business/businessCheckApply`,
    method: 'post',
    data
  })
}

// 正常/禁用
export function manageState(data) {
  return request({
    url: `${baseUrl}/business/manageState`,
    method: 'post',
    data
  })
}

// 新增入驻-输入手机号
export function queryHkyPersonal(data) {
  return request({
    url: `${baseUrl}/user/queryHkyPersonal`,
    method: 'post',
    data
  })
}

// 新增入驻-确认用户账户
export function confirmSettleUser(data) {
  return request({
    url: `${baseUrl}/business/settled`,
    method: 'post',
    data
  })
}

// 编辑入驻-确认用户账户
export function editSettleUser(data) {
  return request({
    url: `${baseUrl}/business/editBusiness`,
    method: 'post',
    data
  })
}

// 认证审核列表
export function queryAuthenticationPage(data) {
  return request({
    url: `${baseUrl}/authentication/queryAuthenticationPage`,
    method: 'post',
    data
  })
}

// 认证明细
export function applyDetail(data) {
  return request({
    url: `${baseUrl}/authentication/applyDetail`,
    method: 'post',
    data
  })
}
// 认证明细
export function editExperience(data) {
  return request({
    url: `${baseUrl}/authentication/editExperience`,
    method: 'post',
    data
  })
}
// 认证明细
export function businessManageDeatil(data) {
  return request({
    url: `${baseUrl}/business_manage/${data.businessTag}/${data.businessType}/${data.userId}`,
    method: 'get',
    data
  })
}
// 认证明细
export function businessManageDeatilNew(data) {
  return request({
    url: `${baseUrl}/business_manage/busi-personal/${data.businessUserId}`,
    method: 'get',
    data
  })
}
// 认领店铺管理
export function getclaimlist(data) {
  return request({
    url: `${baseUrl}/claim/page`,
    method: 'post',
    data
  })
}
// 认领店铺审核

export function claimCheck(data) {
  return request({
    url: `${baseUrl}/claim/check`,
    method: 'post',
    data
  })
}
/**
 * 各种认证
 * type 个人/企业
 * authtype 认证类型
 * */
export function authentication(type, authtype, data, isedit) {
  console.log(type, authtype)
  let url = ''
  // 实名认证
  if (type === 1 && authtype === '1') {
    url = `${baseUrl}/personal/real`
    // 个人身份认证
  } else if (type === 1 && authtype === '2') {
    url = `${baseUrl}/authentication/idAuthentication`
    // 品牌故事认证
  } else if (type === 1 && authtype === '4') {
    url = `${baseUrl}/authentication/shopbrief`
    // 品牌认证
  } else if (type === 1 && authtype === '5') {
    url = `${baseUrl}/authentication/brandAuthentication`
    // 经营地址认证
  } else if (type === 1 && authtype === '6') {
    url = `${baseUrl}/authentication/shopAdressAuthentication`
    // 办公环境认证/店铺环境认证
  } else if (type === 1 && (authtype === '9' || authtype === '10')) {
    url = `${baseUrl}/authentication/environment`
    // 展览认证
  } else if (type === 1 && (authtype === '11' || authtype === '12' || authtype === '13')) {
    url = `${baseUrl}/authentication/experience`
    if (isedit == 1) {
      url = `${baseUrl}/authentication/editExperience`
    }
  }else if (type === 1 && authtype === '14') { // 主页认证
    url = `${baseUrl}/authentication/homepage-name-authentication`
   
  }else if (type === 1 && authtype === '15') { // 简介认证
    url = `${baseUrl}/authentication/shop-introduction`
    // 品牌认证
  }
  return request({
    url: url,
    method: 'post',
    data
  })
}

// 实名认证
export function personalreal(data) {
  return request({
    url: `${baseUrl}/personal/real`,
    method: 'post',
    data
  })
}

// 个人/身份认证
export function personalidentity(data) {
  return request({
    url: `${baseUrl}/personal/identity`,
    method: 'post',
    data
  })
}

// 个人/身份认证
export function realIdentity(data) {
  return request({
    url: `${baseUrl}/personal/real`,
    method: 'post',
    data
  })
}

// 企业身份认证
export function idAuthentication(data) {
  return request({
    url: `${baseUrl}/authentication/idAuthentication`,
    method: 'post',
    data
  })
}

// 经营地址认证/线下实体店认证
export function shopAdressAuthentication(data) {
  return request({
    url: `${baseUrl}/authentication/shopAdressAuthentication`,
    method: 'post',
    data
  })
}

// 简历认证
export function shopbrief(data) {
  return request({
    url: `${baseUrl}/authentication/shopbrief`,
    method: 'post',
    data
  })
}

// 品牌认证
export function brandAuthentication(data) {
  return request({
    url: `${baseUrl}/authentication/brandAuthentication`,
    method: 'post',
    data
  })
}

// 品牌认证
export function environment(data) {
  return request({
    url: `${baseUrl}/authentication/environment`,
    method: 'post',
    data
  })
}

/**
 * 各种认证
 * type 企业
 * authtype 认证类型
 * */
export function authenticationBusiness(authtype, data, isedit) {
  // 认证类型1实名认证,2身份认证,3企业真实性验证,4简介认证,5品牌认证,6经营地址认证,7办公地址认证,
  // 8线下实体店认证9办公环境认证,10店铺环境认证,11展览认证,12经历认证,13获奖认证  debugger
  let url = ''
  // 实名认证
  if (authtype === 3) {
    url = `${baseUrl}/authentication/real`
  } else if (authtype === 4) { // 品牌故事
    url = `${baseUrl}/authentication/shopbrief`
  } else if (authtype === 5) { // 品牌
    url = `${baseUrl}/authentication/brandAuthentication`
  } else if (authtype === 6) { // 经营地址认证
    url = `${baseUrl}/authentication/shopAdressAuthentication`
  } else if (authtype === 9 || authtype === 10) { // 办公环境认证/店铺环境认证
    url = `${baseUrl}/authentication/environment`
  } else if (authtype === 2) { // 身份认证
    url = `${baseUrl}/authentication/idAuthentication`
  } else if (authtype === 11 || authtype === 12 || authtype === 13) { // 经历认证
    url = `${baseUrl}/authentication/experience`
    if (isedit == 1) {
      url = `${baseUrl}/authentication/editExperience`
    }
  } else if (authtype === 8) { // 经营地址认证/线下实体店认证
    url = `${baseUrl}/authentication/shopAdressAuthentication`
  } else if (authtype === 14) { // 主页
    url = `${baseUrl}/authentication/homepage-name-authentication`
  } else if (authtype === 15) { // 简介
    url = `${baseUrl}/authentication/shop-introduction`
  }
  return request({
    url: url,
    method: 'post',
    data
  })
}

// 审核认证
export function checkApply(data) {
  return request({
    url: `${baseUrl}/check/checkApply`,
    method: 'post',
    data
  })
}

// 获取平台
export function getPlatByUserId(data) {
  return request({
    url: `${baseUrl}/business_manage/platform/` + data.tag + `/` + data.type + `/` + data.userId,
    method: 'get'
  })
}


// 获取平台
export function getAddrList(key) {
  return request({
    url: `${baseUrl}/map/search/` + key,
    method: 'get'
  })
}
