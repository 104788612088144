<template>
  <ak-container card :bodyStyle="{padding: 0}" class="ak-card-table">
    <a-space direction="vertical" :size="20">
      <a-card>
        <a-form-model ref="form" class="ak-query-filter" :model="queryFilters" :label-align="'left'"
          :labelCol="{span: 12, offset: 1}" :colon="false">
          <a-row :gutter="24">
            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="手机号" prop="phone">
                <a-input allowClear v-model="queryFilters.phone" placeholder="请输入" />
              </a-form-model-item>
            </a-col>

            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="昵称" prop="name">
                <a-input allowClear v-model="queryFilters.nickName" placeholder="请输入" />
              </a-form-model-item>
            </a-col>

            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="入驻平台" prop="businessType">
                <a-select allowClear v-model="queryFilters.businessType" placeholder="请选择">
                  <a-select-option v-for="item in platformOpts" :key="item.value" :value="item.value">{{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="状态" prop="applyState">
                <a-select allowClear v-model="queryFilters.applyState" placeholder="请选择">
                  <a-select-option v-for="item in statusOpts" :key="item.value" :value="item.value">{{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="入驻时间" prop="settleTime">
                <a-range-picker v-model="queryFilters.settleTime" show-time class="W100" :placeholder="['开始日期', '结束日期']"
                  format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
              </a-form-model-item>
            </a-col>

            <a-col v-bind="$wrapperThreeCol" class="action-btn-main">
              <a-button type="primary" class="ML12" @click="search">查询</a-button>
              <a-button class="ML12" @click="onResetForm">重置</a-button>
            </a-col>
          </a-row>
          <a-button type="primary" class="ML12" @click="modal.phoneVisible = true">新增入驻</a-button>
        </a-form-model>
      </a-card>

      <a-table ref="table" :pagination='pagination' :columns="columns" :data-source="tableList" :scroll="{ x: 100 }"
        @change="handleTableChange" :loading="loading" :rowKey="(record) => record.id">
        <!-- 用户信息 -->
        <template #personalResp="text">
          <a-row type="flex" align="middle">
            <a-col :span="12">
              <img :src="text.headImage" class="headImage" alt="">
            </a-col>
            <a-col :span="12" style="text-align: right;">
              {{ text.name }}<br>{{ text.phone }}
            </a-col>
          </a-row>
        </template>

        <!-- 入驻平台 -->
        <template #businessType="text">
          <span v-if="text === '1'">创作者平台</span>
          <span v-if="text === '2'">独立店</span>
          <span v-if="text === '3'">商家</span>
        </template>
        <!-- 状态 -->
        <template #applyState="text">
          <span v-if="text === 0" style="color: red;">审核中</span>
          <span v-if="text === 1">已通过</span>
          <span v-if="text === 2" style="color: red;">未通过</span>
        </template>
        <!-- 操作 -->
        <template #action="text, record">
          <a-button type="link"
            @click="$router.push({path: '/user-manage/resident-audit/detail', query: {id: record.userId}})">详情
          </a-button>
          <a-button v-if="record.applyState == 0" type="link" @click="audit(record.id)">审核</a-button>
          <a-button v-else type="link"
            @click="$router.push({path: '/user-manage/resident-audit/insert', query: {isedit:1, id: record.userId}})">编辑
          </a-button>
        </template>
      </a-table>
    </a-space>
    <a-modal :visible="modal.phoneVisible" :footer="null" :afterClose="afterClose" @cancel='afterClose' width="35%">
      <template #title>
        新增入驻用户<span style="font-size: 12px; color: rgba(0, 0, 0, 0.3);margin-left: 15px;">搜素用户账号</span>
      </template>
      <div style="display: flex; justify-content: center; flex-wrap: wrap;">
        <a-input style="width: 100%;margin: 8%;" size="large" v-model="userPhone" placeholder="请输入用户手机号" />
        <a-button style="width: 30%;margin: 5% 0 8% 0;" type="primary" @click="checkPhone">查询</a-button>
      </div>
    </a-modal>
    <a-modal :visible="modal.infoVisible" :footer="null" @cancel='infoTableClose' width="80%">
      <template #title>
        新增入驻用户<span style="font-size: 12px; color: rgba(0, 0, 0, 0.3);margin-left: 15px;">确认用户账号</span>
      </template>
      <a-table ref="infoTable" rowKey="userId" size="small" :pagination="false" :columns="infoColumns"
        :data-source="infoData">
        <template #sex="text">
          <span>{{ text == '1' ? '男' : '女' }}</span>
        </template>
        <template #headImage="text">
          <img :src="text" class="headImage" alt="">
        </template>
        <template #userTabs="text">
          <span v-for="(item,index) in text" :key="index">{{ item }}、</span>
        </template>
      </a-table>
      <div style="display: flex; align-items: center; justify-content: center;margin: 10% 0 30px 0;">
        <a-button type="primary" @click="sendInsertPage">确认</a-button>
        <a-button style="margin-left: 15px;" @click="modal.infoVisible = false">取消</a-button>
      </div>
    </a-modal>
    <a-modal :visible="modal.auditVisible" :closable="false" :centered="true" :title="null" :footer="null"
      width="300px">
      <div style="font-size: 16px; font-weight: bold;">
        <a-icon type="exclamation-circle" theme="filled" style="color: #FAAD14;font-size: 18px;" />
        审核入驻用户
      </div>
      <div style="display: flex; justify-content: center;margin: 30px 0;">
        <a-radio-group :defaultValue="1" v-model="applyState">
          <a-radio :value="1">已通过</a-radio>
          <a-radio :value="2">未通过</a-radio>
        </a-radio-group>
      </div>
      <div>
        <a-button style="width: 150px;" type="primary" @click="verifyAudit">确认</a-button>
        <a-button style="margin-left: 15px;" @click="modal.auditVisible = false">取消</a-button>
      </div>
    </a-modal>

    <!-- 未找到手机号 弹窗 -->
    <NotPhoneModal :visible="modal.sureVisible" @closeNotPhone="modal.sureVisible = false" />
  </ak-container>

</template>

<script>
  import NotPhoneModal from '@/components/NotPhoneModal'
  import {
    message
  } from 'ant-design-vue'

  import * as Settle from '@/api/user/settle'

  const columns = [ {
    title: '店铺名称',
    dataIndex: 'shopName',
    scopedSlots: {
      customRender: 'shopName'
    },
    align: 'center',
    width: 200
  },
    {
    title: '用户信息',
    dataIndex: 'personalResp',
    scopedSlots: {
      customRender: 'personalResp'
    },
    align: 'center',
    width: 200
  }, {
    title: '入驻平台',
    dataIndex: 'businessType',
    scopedSlots: {
      customRender: 'businessType'
    },
    align: 'center',
    width: 160
  }, {
    title: '状态',
    dataIndex: 'applyState',
    scopedSlots: {
      customRender: 'applyState'
    },
    align: 'center',
    width: 80
  }, {
    title: '最后IP属地',
    dataIndex: 'ipLocation',
    align: 'center',
    width: 120
  }, {
    title: '申请入驻时间',
    dataIndex: 'createdTime',
    align: 'center',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.createdTime > b.createdTime ? 1 : -1
    },
    width: 200
  }, {
    title: '入驻时间',
    dataIndex: 'applyTime',
    align: 'center',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.applyTime > b.applyTime ? 1 : -1
    },
    width: 200
  }, {
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: {
      customRender: 'action'
    },
    align: 'center',
    width: 200
  }]

  const infoColumns = [{
    title: '头像',
    dataIndex: 'headImage',
    scopedSlots: {
      customRender: 'headImage'
    },
    align: 'center'
  }, {
    title: '账号昵称',
    dataIndex: 'name',
    align: 'center'
  }, {
    title: '联系方式',
    dataIndex: 'phone',
    align: 'center'
  }, {
    title: '性别',
    dataIndex: 'sex',
    scopedSlots: {
      customRender: 'sex'
    },
    align: 'center'
  }, {
    title: 'Ta的兴趣',
    dataIndex: 'userTabs',
    scopedSlots: {
      customRender: 'userTabs'
    },
    align: 'center'
  }, {
    title: '发图文',
    dataIndex: 'issueImageNum',
    align: 'center'
  }, {
    title: '发视频',
    dataIndex: 'issueVideoNum',
    align: 'center',
    width: 100
  }, {
    title: '发文章',
    dataIndex: 'issueArticleNum',
    align: 'center',
    width: 100
  }, {
    title: '发作品',
    dataIndex: 'issueWorksNum',
    align: 'center',
    width: 100
  }, {
    title: '创建时间',
    dataIndex: 'createdTime',
    align: 'center'
  }]

  export default {
    components: {
      NotPhoneModal
    },
    data() {
      return {
        loading: false,
        pagination: {
          total: 0,
          pageSize: 10, // 每页中显示10条数据
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
          showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
          current: 1
        },
        // 查询参数
        queryFilters: {
          page: 1,
          pageSize: 10,
          date: []
        },
        modal: {
          phoneVisible: false,
          infoVisible: false,
          auditVisible: false,
          sureVisible: false
        },
        columns,
        infoColumns,
        data: [],
        infoData: [],
        platformOpts: [{
          value: 1,
          label: '创作者平台'
        }, {
          value: 2,
          label: '独立店'
        }, {
          value: 3,
          label: '商家'
        }],
        statusOpts: [{
          value: 0,
          label: '审核中'
        }, {
          value: 1,
          label: '已通过'
        }, {
          value: 2,
          label: '未通过'
        }],
        tableList: [],
        userPhone: '',
        applyState: 1,
        userId: ''
      }
    },
    created() {
      this.search()
    },
    methods: {
      afterClose() {
        this.modal.phoneVisible = false
      },
      infoTableClose() {
        this.modal.infoVisible = false
      },
      handleTableChange(pagination, filters, sorter) {
        let that = this;
        that.sortedInfo = sorter;
        const pager = {
          ...that.pagination
        };
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        that.pagination = pager;
        this.queryFilters.page = pagination.current;
        this.queryFilters.pageSize = pagination.pageSize;
        this.queryFilters.applyTimeOrder = "";
        this.queryFilters.settledTimeOrder = "";
        if (sorter && sorter.order) {
          if (sorter.field == "applyTime") {
            if (sorter.order == "ascend") {
              this.queryFilters.applyTimeOrder = "asc";
            }
            if (sorter.order == "descend") {
              this.queryFilters.applyTimeOrder = "desc";
            }
          }
          if (sorter.field == "createdTime") {
            if (sorter.order == "ascend") {
              this.queryFilters.settledTimeOrder = "asc";
            }
            if (sorter.order == "descend") {
              this.queryFilters.settledTimeOrder = "desc";
            }
          }
        }
        that.queryData()
      },
      // 加载数据方法
      queryData() {
        this.loading = true
        let date = {
          page: this.queryFilters.page,
          pageSize: this.queryFilters.pageSize,
          phone: this.queryFilters.phone,
          nickName: this.queryFilters.nickName,
          businessType: this.queryFilters.businessType,
          businessTag: this.userType,
          applyState: this.queryFilters.applyState,
          createdTimeStart: this.queryFilters.settleTime ? this.queryFilters.settleTime[0] : '',
          createdTimeEnd: this.queryFilters.settleTime ? this.queryFilters.settleTime[1] : '',
          applyTimeOrder: this.queryFilters.applyTimeOrder,
          settledTimeOrder: this.queryFilters.settledTimeOrder
        }
        Settle.querySettle(date).then((res) => {
          if (res.code === 0) {
            this.tableList = res.data.list
            this.pagination.total = res.data.total
          }
          this.loading = false
        }).catch(e => {
          this.message = e.message || '查询错误'
        }).finally(() => {
          this.loading = false
        })
      },
      getUserByPhone() {
        let that = this;
        that.infoData = []
        const param = {
          phone: that.userPhone
        }
        Settle.queryHkyPersonal(param).then((res) => {
          if (res.code === 0) {
            that.infoData.push(res.data);
            that.modal.infoVisible = true
            that.modal.phoneVisible = false
          } else {
            that.modal.sureVisible = true
          }
        }).catch(e => {
          that.message = e.message || '查询错误'
        }).finally(() => {})
      },
      search(){
        this.pagination.current=1;
        this.queryFilters.page = 1;
        this.queryData();
      },
      // 重置
      onResetForm() {
        this.$refs.form.resetFields()
        this.queryFilters = this.$options.data.call(this).queryFilters
        this.queryData()
      },
      audit(id) {
        this.userId = id
        this.modal.auditVisible = true
      },
      verifyAudit() {
        const param = {
          id: this.userId,
          applyState: this.applyState
        }
        let that = this;
        Settle.confrimSettleIn(param).then((res) => {
          if (res.code === 0) {
            message.success('审核成功')
            that.queryData()
          }
        }).catch(e => {
          this.message = e.message || '查询错误'
        }).finally(() => {})
        this.modal.auditVisible = false
      },
      checkPhone() {
        let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
        if (strTemp.test(this.userPhone)) {
          this.getUserByPhone()
        } else {
          this.modal.sureVisible = true
          return false
        }
      },
      sendInsertPage() {
        let phone = this.infoData[0].phone;
        this.$router.push({
          path: '/user-manage/resident-audit/insert?phone=' + phone
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .ant-card-body {
    padding: 18px;
  }

  /deep/ .ant-col-xl-8 {
    padding-left: 0 !important;
  }

  /deep/ .ant-space {
    width: 96%;
    display: flex;
    margin: 10px auto;
  }

  /deep/ .ant-form-item-label-left {
    text-align: left !important;
  }
</style>
